<template>
  <div class="settingMain">
    <NavbarBack :title="'ข้อมูลการยินยอม'" />
    <Category
      class="wrapCategory"
      :data="categoryData"
      v-on:click="categoryClick"
    />
    <div class="msg" v-if="loading">โหลดข้อมูล...</div>
    <div class="msg" v-if="!loading && categoryData.length === 0">
      ไม่พบข้อมูล
    </div>
    <div
      v-show="openConsent.id"
      :class="[{ show: openConsent.id }, 'modalConsent']"
    >
      <div class="consentModalBody">
        <div class="closeIcon" @click="openConsent = {}"></div>
        <!-- <div class="title">{{ openConsent.title }}</div> -->
        <div ref="wrapContent" class="wrapContent">
          <div class="content" v-html="openConsent.content"></div>
        </div>
        <div class="bottomSection">
          <div class="confirmBtn" @click="openConsent = {}">ปิด</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Category from "@/views/components/Category";
import NavbarBack from "@/components/NavbarBack";
import { GET } from "@/utils/http";

export default {
  components: { Category, NavbarBack },
  data() {
    return {
      categoryData: [],
      openConsent: {},
      loading: false,
    };
  },
  watch: {
    openConsent: {
      deep: true,
      handler() {
        this.$refs.wrapContent.scrollTo(0, 0);
      },
    },
  },
  methods: {
    categoryClick(item) {
      this.openConsent = item.data;
    },
    async fetchConsent() {
      this.loading = true;
      try {
        const { status, body } = await GET(`/api/consents/complete`);

        if (status !== 200) {
          throw "no case " + status;
        }
        this.categoryData = body.map((e) => ({
          name: e.title,
          data: e,
        }));
      } catch (error) {
        console.error("get consent complete error: ", error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.fetchConsent();
  },
};
</script>
<style lang="scss" scoped>
.settingMain {
  padding-top: 50px;
  background: #fff;
  height: 100%;
}

.msg {
  margin: 40px auto;
  text-align: center;
}

.title {
  text-align: center;
  font-size: 20px;
  padding: 40px 0 10px 0;
}

.wrapCategory {
  padding-top: 10px;
}

/* The Modal (background) */
.modalConsent {
  display: flex; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  // padding-top: 100px; /* Location of the box */
  left: 0;
  opacity: 0;
  top: -100%;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  &.show {
    opacity: 1;
    top: 0;
    > .modal-content {
      -webkit-animation-name: animatetop;
      -webkit-animation-duration: 0.4s;
      animation-name: animatetop;
      animation-duration: 0.4s;
    }
  }
}

/* Modal Content */

/* The Close Button */
.close {
  position: absolute;
  right: 10px;
  top: 10px;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* Add Animation */
@-webkit-keyframes animatehide {
  from {
    top: 0;
    opacity: 1;
  }
  to {
    top: -300px;
    opacity: 0;
  }
}

@keyframes animatehide {
  from {
    top: 0;
    opacity: 1;
  }
  to {
    top: -300px;
    opacity: 0;
  }
}

.consentModalBody {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  // width: 80%;
  border-radius: 10px;
  position: relative;
  height: 100vh;
  max-width: 600px;
  text-align: center;
  font-size: 15px;
  color: #2c2c2c;
  // overflow: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.title {
  font-size: 20px;
  color: rgb(34, 34, 34);
  font-weight: bold;
  margin: 5px auto;
}

.wrapContent {
  flex: 1 1 auto;
  overflow-y: auto;
  height: 0px;
}

.bottomSection {
  padding: 0 15px;
  background-color: white;
  width: 100%;
}

.content {
  box-shadow: 0 0 3px #00000025;
  border-radius: 10px;
  margin: 2px;
  padding: 15px 5px;
  font-size: 14px;
  line-height: 20px;
  text-align: unset;
  margin-bottom: 10px;
}

.confirmBtn {
  margin: 10px auto 0 auto;
  max-width: 400px;
  display: flex;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #808080;
  justify-content: center;
  align-items: center;
  height: 40px;
  color: #343434;
}
</style>